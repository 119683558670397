import React from 'react';
import { Modal } from 'react-bootstrap';

function WatchVideoModal({ isOpen, toggle }) {
    return (
        <Modal
            show={isOpen}
            onHide={toggle}
            centered
            className="watchVideoDemo"
            size="lg"
        >
            <Modal.Body>
                <div className="howToOrderVideo">
                    <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/DerCRVja8O4?autoplay=1"
                        frameBorder="0"
                        allowfullscreen="allowFullScreen"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        title="CheapestEssay Pitch"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default WatchVideoModal;